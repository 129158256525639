.text-content{

	text-align: center;
	font-size: 14px;

	.grow-1:nth-child(2){
		margin: 0 30px;
	}

	@at-root #{&}__title{
		font-size: 25px;
		font-weight: bold;

		margin: 20px 0;
	}

	.icon{
		position: relative;
		font-size: 30px;
		a{
			color: darken($brand-primary,5%);
			width: 100%;
			height: 100%;
		}
	    width: 64px;
	    height: 64px;
	    cursor: pointer;
	}

	.icon:before{
		position: absolute;
		width: 100%;
		height: 100%;
		left: -1px;
		top: -1px;
		border: 3px solid darken($brand-primary,5%);
		border-radius: 50%;
		content: "";
		transition: opacity 0.1s ease-in-out;
	}

	.icon:hover::before{
		opacity: 0;
	}
	
	.icon:after{
		position: absolute;
		top: -2px;
		left: -2px;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		box-sizing: content-box;
		padding: 1px;
		content: "";
		box-shadow: 0 0 0 3px #333;
		transition: all 0.2s ease-in-out;
		transform: scale(.8);
		opacity: 0;
	}

	.icon:hover::after{
		opacity: 1;
		transform: scale(1);
	}
}

.text-content--lines{
	.grow-1 + .grow-1{
		margin-left: 30px;
	}
}

@media (max-width: 1024px){
	.text-content{
		.grow-1:nth-child(2){
			margin: 0;
		}
	}

	.text-content--lines{
		.grow-1 + .grow-1{
			margin-left: 0;
		}
	}
}