.library-img{

	.portfolio-image{
		img{
			width: 100%;
		}
	}

	.portfolio-description{
		padding: 15px 0;
		font-size: 13px;
		font-weight: bold;
		// padding-bottom: 30px;

		i{
			margin-right: 6px;
		}
	}
}