.partners-section{

	img{
		max-width: 250px;
	}

	@at-root #{&}__title{
		font-size: 35px;
		letter-spacing: 5px;
		font-weight: bold;
		margin-bottom: 40px !important;

		background-color: #000;

		span{
			padding-bottom: 40px;
			position: relative;
			top: 24px;
		}
	}
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
	.partners-section{
		a + a{
			margin-top: 30px;
		}
	}
}