.main-menu{
	width: 95%;

	.search-form{
		width: 30vw;	
	}
}

@media (max-width: 1024px){
	.main-menu{
		.search-form{
			width: 23vw;	
		}
	}
}