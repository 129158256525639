/**
 * MIXINS
 */

// Opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	// IE8 filter
	$opacity-ie: ($opacity * 100);
	filter: alpha(opacity=$opacity-ie);
}

/**
 * aspect-ratio
 */
@mixin aspect-ratio($width, $height) {
	display: block;
	content: "";
	// width: 100%;
	padding-top: ($height / $width) * 100%;
}

@mixin flex() {
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin transform-y($translate_y) {
	-webkit-transform: translateY($translate_y);
	-moz-transform: translateY($translate_y);
	-ms-transform: translateY($translate_y);
	-o-transform: translateY($translate_y);
	transform: translateY($translate_y);
}

@mixin gradient-background($color_stop_1, $color_stop_2) {
	background: $color_stop_1; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient($color_stop_1, $color_stop_2); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($color_stop_1, $color_stop_2); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($color_stop_1, $color_stop_2); /* For Firefox 3.6 to 15 */
	background: linear-gradient($color_stop_1, $color_stop_2); /* Standard syntax */
}