.gototop-override{
	padding: 5px 11px 6px;
}

@media (max-width: 1280px){
    .gototop-override{
    	padding: 3px 6px 4px;
    }
}

@media (max-width: $screen-sm - 1){
    .gototop-override{
    	padding: 7px 11px 8px;
    }
}