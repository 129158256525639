

.slick-arrow {
	font-size: 0;
	line-height: 0;
	outline: 0;
	
	&.slick-next:before,
	&.slick-prev:before{
		content: '';
	}

	&.slick-prev:hover,
	&.slick-next:hover,
	&.slick-next:focus{
		background-color: transparent;
	}
}

.slick-dots{
	bottom: -30px;
	left: -5px;

}

.slick-dots li button:before{
	font-size: 12px;
	color: #fff;
}

.slick-dots li.slick-active button:before{
	color: $brand-primary;
}

@media (max-width: $screen-sm - 1) and (orientation: portrait){
    .slick-dots li button:before{
		font-size: 6px;
	}
}
