.plyr{
	.plyr__control--overlaid{
		background: rgba($brand-primary,.8) !important;
	}

	.plyr__control:hover{
		background: $brand-primary !important;
	}

	.plyr__control[aria-expanded=true] {
	    background: $brand-primary !important;
	}
}

.plyr--full-ui input[type=range]{
	color: $brand-primary !important;
}