.slide-items{

	&:not(:first-child){
		margin-left: 10px;
	}

	outline: none;

	@at-root #{&}__img-container{
		margin: 0 auto;
		height: 0;
		position: relative;

		@include aspect-ratio(292px, 432px);
		// min-width: 170px;
	}

	@at-root #{&}__img{
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	 @at-root #{&}__tag {
	 	padding-left: 10px;
	 	min-height: 150px;
	 	position: relative;

	 	.item-tags:not(:first-child) {
	 		display: none;
	 	}
	 }

	 @at-root #{&}__container{
	 	top: -5px;
	 	left: -35px;
	 	*{
	 		font-family: gautami;
	 	}

 		button:hover{
 			color: #000;
 		}
	 	
	 }

	 @at-root #{&}__btn {
	 	padding-right: 95px;

	 	.btn-style-dark + .btn-style-dark{
	 		margin-left: 10px;
	 	}
	 }


	 @at-root #{&}__content{
	 	color: #fff;

	 	span:not(.slide-items__title) {
	 		font-size: 16px;
	 	}

	 	span + span{
	 		margin-top: 10px;
	 	}
	 }

	 @at-root #{&}__title {
	 	label,
	 	i.fa-star{
	 		color: $brand-primary;
	 		font-weight: bold;
	 		font-size: 20px;
	 		margin-bottom: 0;
	 	}
	 	label{
	 		.glyphicon{
	 			top: 3px;
	 			font-size: 18px;
	 			color: $brand-primary;
	 		}
	 	}
	 }

	 @at-root #{&}__arrows {
	 	position: absolute;
	 	color: $brand-primary;
	 	font-size: 42px;

	 	right: 10px;
	 	top: 4px;

	 	#arrow-next,
	 	#arrow-prev,
	 	a{
	 		border-radius: 3px;
	 	}

	 	a{
	 		color: $brand-primary;
	 	}

	 	.flex-container .grow-1{
	 		border: 1px solid $brand-primary;
	 		width: 50px;
	 		height: 50px;
	 		cursor: pointer;
	 	}

	 	.flex-container{
	 		.grow-1 + .grow-1{
	 			margin-top: 10px;
	 		}
	 	}
	 }
}

@media (width: 1366px) and (height: 800px){
	.slide-items{
		@at-root #{&}__container{
			left: -30px;
		}
	}
}

@media (width: 1326px) and (height: 550px){
    .slide-items{
    	@at-root #{&}__container{
    		left: -17px;
    	}
    }
}

@media (max-width: 1280px){
    .slide-items{
    	@at-root #{&}__container{
    		left: -5px;
    	}
    }
}

@media (max-width: $screen-sm - 1){
    .slide-items{
    	@at-root #{&}__tag {
    		padding-left: 0;
    		padding-top: 20px;
    	}

    	@at-root #{&}__btn {
	 		padding-right: 0;
	 		padding-top: 20px;
	 	}

	 	@at-root #{&}__container{
	 		left: auto;

	 		a{
	 			margin-left: 0;
	 		}
	 	}
    }
}

@media (max-width: $screen-sm - 1) and (orientation: portrait){
    .slide-items{
    	&:not(:first-child){
    		margin-left: 0;
    	}

    	@at-root #{&}__tag {
    		padding-left: 0;
    		padding-top: 20px;
    	}

    	@at-root #{&}__content{
    		span:not(.slide-items__title),
    		span:not(.glyphicon){
    			font-size: 14px;
    		}
    	}

    	@at-root #{&}__btn {
	 		padding-right: 0;
	 		padding-top: 20px;
	 	}
    }
}

@media (max-width: $screen-sm - 1) and (orientation: landscape){
    .slide-items{
    	@at-root #{&}__tag{
    		padding-left: 20px;
    	}
    }
}
