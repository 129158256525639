.library-btn{
	padding-left: 0;
	li{
		padding: 10px;
		padding-top: 20px;
		// border-radius: 4px;
		font-weight: 600;
	    cursor: pointer;
	    display: inline-block;
	    font-size: 16px;
	    letter-spacing: 2px;
	  
	    line-height: 10px;
	    position: relative;
	    color: $brand-primary;
	    // width: 110px;
	    width: 155px;
	    text-align: center;

	    a{
	    	color: #fff !important; 
	    }
	}

	li + li{
		margin-left: 10px;
	}

	.first{
		// color: $brand-primary;
		padding-top: 20px;
	}

	.ptf-active{
		background-color: #d82731;
	    // width: 110px;
	    text-align: center;
	}
}

.library-btn + .library-btn{
	margin-top: 7px;
}

// .library-btn--sort{
// 	color: $brand-primary;
// 	border-left: 1px solid $brand-primary;
// }

.library-btn--menu{
	margin-top: 0;
	li{
		background-color: #000;
		// border-radius: 30px;
		color: #fff;
	}
}

.library-btn--space{
	margin-left: 5px;
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
	.library-btn{
		li + li{
			margin-left: 0;
		}
	}

	.library-btn + .library-btn{
		margin-top: 0;
		margin-left: 5px;
	}

	.library-btn--menu{
		li:nth-last-child(-n+2){
			margin-top: 5px;
		}
	}

	.library-btn--space{
		margin-left: 0;
	}
	
}