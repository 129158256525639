.movie-detail{
	padding-top: 40px;

	@at-root #{&}__container{
		border: 3px solid $brand-primary;
		margin-right: 20px;
		// min-width: 605px;
		min-width: 65%;
	}

	@at-root #{&}__video{
		height: 0;
		position: relative;
		// padding-bottom: 56.25%;
		@include aspect-ratio(640px, 360px);

		iframe,
		#videoPlayer,
		.plyr{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.plyr__poster{
			left: -1px;
			top: -1px;
		}
	}

	@at-root #{&}__poster{
		height: 0;
		top: 1px;
		left: 0;
		position: relative;
		padding-top: 7px;
		border: 2px solid $brand-primary;

		@include aspect-ratio(292px, 432px);
		// min-width: 177px;

		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	@at-root #{&}__title{
		font-weight: 700;
		font-size: 30px;
		line-height: 30px;

		label{
			color: $brand-primary;
		}
	}

	@at-root #{&}__stars{
		min-height: 100px;
		padding-top: 20px;
		padding-bottom: 20px;

		.slide-items__arrows{
			top: 25px;
			right: 0;
		}
	}

	@at-root #{&}__text{
		font-weight: 700;
		font-size: 20px;
		margin-top: 10px;

		label{
			font-size: 22px;
			margin-bottom: 0;
		}

		span:first-child{
			color: $brand-primary;
		}

		span + span{
			margin-top: 15px;
		}

		.glyphicon{
			font-size: 18px;
			top: 2px;
		}

		@media (min-width: 769px) { 
			width: 40%;
			// margin-top: -50px;
		}

		@media (min-width: 1200px) {
			width: auto;
			margin-top: 0;
		}
	}

	// @at-root #{&}__desc{
	// 	min-height: 270px;
	// 	font-size: 19px;
	// 	line-height: 27px;
	// 	text-align: justify;
	// 	position: relative;
	// 	margin-right: 20px;
	// 	margin-bottom: 20px;

	// 	.synopsis{
	// 		top: -5px;
	// 	    left: 0;
	// 	    position: relative;
	// 	}
	// }

	@at-root #{&}__content{
		margin-top: 10px;
	}
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
    .movie-detail{
    	@at-root #{&}__container{
    		margin-right: 0;
    		margin-bottom: 20px;
    		min-width: 100%;
    	}

    	@at-root #{&}__content{
    		flex-direction: column;

    	}

    	@at-root #{&}__title{
    		font-size: 20px;

    		.glyphicon{
    			font-size: 15px;
    		}
    	}
    }
}

@media (max-width: $screen-sm - 1) and (orientation: portrait){
    .movie-detail{
    	@at-root #{&}__stars{
    		padding-top: 20px;
    	}

    	@at-root #{&}__desc{
    		margin-right: 0;
    	}

    	.slide-items__arrows{
    		position: relative;
    		bottom: 0;
    	}
    }
}

@media (min-width: 769px) { 
	.movie-detail {
		@at-root #{&}__desc{
			// min-height: 270px;
			// font-size: 19px;
			// line-height: 27px;
			text-align: justify;
			position: relative;
			margin-right: 20px;
			font-size: 85%;
	
			.synopsis{
				top: -5px;
				left: 0;
				position: relative;
			}
		}
	}
}

@media (min-width: 1200px) { 
	.movie-detail {
		@at-root #{&}__desc{
			text-align: justify;
			position: relative;
			margin-right: 20px;
			font-size: 95%;
	
			.synopsis{
				top: -5px;
				left: 0;
				position: relative;
			}
		}
	}
}