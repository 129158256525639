$sticky-background-default: #fff;
$sticky-background-instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$black: #000000;

.social-media-container{
	position: fixed;
	left: -140px;
	top: 180px;
	z-index: 21;

	ul{
		margin: 0;
		padding: 0;
		// background-color: $black;

		li{
			transition: all 0.25s ease-in-out;
			i{
				bottom: -2px;
				position: relative;
			}
		}

		li:hover{
			margin-right: -140px;
		}
	}

	@at-root #{&}__item{
	    color: #efefef;
	    list-style-type: none;
	    padding: 0px;
	    margin: 0px 0px 1px 0px;
	    cursor: pointer;

	    a{
	    	width: 140px;
	    	color: $brand-primary;
    	    font-size: 16px;
    	    font-weight: 700;
    	    text-align: center;

    	    &:hover{
    	    	color: $brand-primary;
    	    }
	    }
	}

	@at-root #{&}__icon{
		width: 50px;
		height: 50px;
	    cursor: pointer;
	    border: 3px solid $brand-primary;
	    
	    border-radius: 0 3px 3px 0;

	    a{
	    	color: $brand-primary;
	    	font-size: 20px;

	    	&:hover{
    	    	color: $brand-primary;
    	    }
	    }
	}

	.social-media-container__icon,
	.social-media-container__item{
		background-color: $black;
	}
}

@media (max-width: 1680px){
    .social-media-container{

		@at-root #{&}__icon{
			width: 35px;
			height: 35px;
		}
    }
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
    .social-media-container{
    	// top: auto;
    	// bottom: 0;
    	left: 0;

    	ul{
    		// @include flex();

    		li:hover{
    			margin-right: 0;
    		}
    	}
    }
}

@media (max-width: 1280px){
    .social-media-container{
		@at-root #{&}__icon{
			width: 40px;
			height: 40px;
		}
    }
}