.form-content{
	width: 29%;

	.panel-body{
		width: 100%;
	}

	.form-group{
		input[type="text"]{
			font-family: gautami;
			padding-top: 15px;
			padding-bottom: 7px;

			color: #000000;
		}

		input::placeholder{
			color: #a1a2a2;
			// position: relative;
			// top: 5px;
		}
	}

	.col-md-12{
		padding: 0;
	}

	.form-group + .form-group{
		margin-top: 15px;
	}

	.form-group:last-child{
		margin-top: 0;
	}

	@at-root #{&}__title{
		font-size: 30px;
		font-weight: bold;
	}

	.check{
		font-size: 12px;
		margin: 15px 0;

		input{
			position: relative;
			top: 2px;
		}
	}
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
	.form-content{
		width: 100%;
	}
}