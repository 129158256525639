.movie-button {
	// position: absolute;
	// bottom: -67px;
	// right: 220px;

    // height: 42px;
	// cursor: pointer;
	// z-index: 5;
	// border-radius: 3px;

	.flex-container{
		.grow-1{
			width: 140px;
		}

		.grow-1 + .grow-1{
			margin-left: 15px;
		}
	}

	.flex-container + .flex-container{
		margin-top: 16px;
	}

	@at-root #{&}__icon{
		height: 100%;
		background-color: $brand-primary;
		// border: 5px solid #000;
		border-radius: 3px;
		// box-shadow: inset 0 0 5px 2px darken($brand-primary,20%);
		// margin-right: 5px;
	}

	@at-root #{&}__play{
		height: 100%;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAM1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACjBUbJAAAAEHRSTlMADx8vP09fb3+Pn6+/z9/v+t8hjgAAARdJREFUeAHt2kGO4zAMBdFQlhXaocW6/2lnO2hk3R9osk7wFkEskXr9X9d1Xdd1Xdd1Xbf8S+MXAcGX5utnfpsYQLoYAPsUAyCGGACXiQHkWwyAZ4oB8BliALiJAeQSAyCmGAD3EANINy0A9ikGQBxiANwmBpAuBsCeYgDEEAPgMjGAXGIAPFMMgM8QA8BNDGAvMQBiigFwDzGAdDEA9ikGQBxiAFwmBpBvMQD2FAMghhgAbmIAucQAeGZtQK7SP8IYlf+I8l36YxRH5QNJeulDaczSFxO3ypdT8fU8V+kRTYzKY7r00qPaOCqP69Ot8somZum1nVvl1e0zS6/vL6v8hGOfpZ/x3PaHnnJ1Xdd1Xdd1Xdd1/wD+IPgr4/EnwQAAAABJRU5ErkJggg==);
		background-repeat: no-repeat;
		background-position: 58px center;
		background-size: 35px;
		box-shadow: inset 0 0 5px 2px darken($brand-primary,20%);
	}

	@at-root #{&}__title{
		// height: 100%;
		// border: 5px solid #000;
		// font-family: Gautami;
		font-weight: 700;
		font-size: 20px;
		background-color: $brand-primary;
		border-radius: 3px;
		color: #fff;
		height: 100%;

		span{
			font-family: Gautami;
			top: 12px;
			font-size: 16px;
			letter-spacing: 1px;
			position: absolute;
		}
	}

	@at-root #{&}__title:hover{
		color: #000;
	}

	a{
		color: #000;
	}

	a:hover{
		color: #000;
	}
}

.movie-button--search{
	margin-top: 10px;
	margin-bottom: 40px;
}

@media (min-width: 769px) { 
	.movie-button {
		position: absolute;
		bottom: -60px;
		right: 155px;

		height: 42px;
		cursor: pointer;
		z-index: 5;
		border-radius: 3px;
	}
}

@media (min-width: 1200px) { 
	.movie-button {
		position: absolute;
		bottom: -67px;
		right: 245px;

		height: 42px;
		cursor: pointer;
		z-index: 5;
		border-radius: 3px;
	}
}