/**
 * FLEX GRID
 */

.flex-container{
	// max-width: 1160px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.flex-basis{
	flex-basis: 0; //
}

.flex-main{
	flex: 1 100%; //All items to be 100% width, via flex-basis
}

.flex-row,
.flex-col,
.wrapper,
.wrapper-flip {
	@include flex();
	position: relative;
}

// WRAP
.wrapper{
	flex-flow: row wrap;
}

.wrapper-flip{
	flex-flow: row wrap-reverse;
}
// ======= END =======

// DIRECTION
.flex-row{
	flex-flow: row nowrap;
}

.flex-col{
	flex-flow: column nowrap;
}

.flip-row{
	flex-flow: row-reverse nowrap;
}

.flip-col{
	flex-flow: column-reverse nowrap
}
// ======= END =======

// JUSTIFY CONTENT
.justify-start{
	justify-content: flex-start;
}

.justify-center{
	justify-content: center;
}

.justify-end{
	justify-content: flex-end;
}

.justify-between{
	justify-content: space-between;
}

.justify-around{
	justify-content: space-around;
}

.justify-evenly{
	justify-content: space-evenly;
}
// ======= END =======

// ALIGN ITEMS
.items-start{
	align-items: flex-start;
}

.items-center{
	align-items: center;
}

.items-end{
	align-items: flex-end;
}

.items-baseline{
	align-items: baseline;
}

.items-stretch{
	align-items: stretch;
}
// ======= END =======

// ALIGN CONTENTS
.content-start{
	align-content: flex-start;
}
.content-center{
	align-content: center;
}
.content-end{
	align-content: flex-end;
}
.content-stretch{
	flex-content: stretch;
}
.content-between{
	align-content: space-between;
}
.content-around{
	align-content: space-around;
}
// ======= END =======

.grow-1{
	flex-grow: 1;
}

.grow-1-half{
	flex-grow: 1.5;
}

.grow-2{
	flex-grow: 2;
}

.grow-2-half{
	flex-grow: 2.5;
}

.grow-3{
	flex-grow: 3;
}

.grow-3-half{
	flex-grow: 3.5;
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
	.flex-col-xs{
		flex-direction: column;
	}

	.flex-row-xs{
		flex-direction: row;
	}

	.items-start-xs{
		align-items: flex-start;
	}

	.items-center-xs{
		align-items: center;
	}

	.items-end-xs{
		align-items: flex-end;
	}

	.justify-start-xs{
		justify-content: flex-start;
	}

	.justify-center-xs{
		justify-content: center;
	}

	.justify-end-xs{
		justify-content: flex-end;
	}

	.flex-basis-auto-all{
		flex-basis: auto;
	}

	.col-reverse-xs{
		flex-direction: column-reverse;
	}

	.flex-col-xs-phone{
		flex-direction: column;
	}
}

@media (max-width: $screen-sm - 1) and (orientation: portrait){
	.flex-col-xs-p{
		flex-direction: column;
	}

	.flex-row-xs-p{
		flex-direction: row;
	}

	.flex-basis-auto{
		flex-basis: auto;
	}
}

@media (max-width: 1024px) and (orientation: portrait){
	.flex-basis-auto-all{
		flex-basis: auto;

	}

	.flex-col-xs{
		flex-direction: column;
	}

	.justify-center-xs{
		justify-content: center;
	}

	.items-center-xs{
		align-items: center;
	}
}

@media (max-width: $screen-sm - 1) and (orientation: landscape){
	.flex-col-xs-phone{
		flex-direction: row;
	}
}

@media (max-width: 812px) and (orientation: landscape){
	.flex-col-xs-phone{
		flex-direction: row;
	}
}