.hr-override{
	width: 100%;
    margin: 20px auto 20px;
    font-family: gautami;

    border-top-style: solid;
    border-top-width: 1px;
    border-radius: 15px 15px 0 0;
    border-right: 1px solid;
    border-left: 1px solid;

    color: $brand-primary;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    height: 20px;
    line-height: 20px;

    abbr{
    	padding: 2px 0;
    	padding-left: 15px;
    	padding-right: 10px;
    	
    	background-color: #fff;
	    position: relative;
	    top: -7px;
	    letter-spacing: .2em;
	    text-transform: uppercase;
    }
}