.mobile-menu-container{
	ul{
		margin-right: -100vw;
		transition: all 200ms ease-in-out;
		position: absolute;
		background-color: #000000;
		right: 0;
		top: 45px;
		padding: 0 10px 10px 10px;
		list-style: none;
		li{
			padding: 0 90px;
			padding-top: 20px;
			padding-bottom: 10px;
			text-align: center;
			a{
				color: #fff;
				font-family: gautami;
				
				&:hover{
					color: $brand-primary;
				}
			}
		}

		li + li{
			border-top: 1px solid $brand-primary;
		}
	}
}