.home-logo{
	max-width: 60px;//400px;
	padding-right: 10px;
	
	img{
		width: 100%;
		height: 100%;
	}

	@at-root #{&}__title{
		color: $brand-primary;
		font-weight: 900;
		font-family: gautami;
		font-size: 30px;
		padding-top: 18px;
	}

	@at-root #{&}__tag-line{
		color: $brand-primary;
		font-weight: bold;
		padding-left: 20px;
		width: 300px;
		font-style: italic;
		font-family: gautami;
		padding-top: 12px;
	}
}


@media (max-width: 1024px) and (orientation: portrait){
	.home-logo{
		@at-root #{&}__tag-line{
			display: none;
		}
	}
}