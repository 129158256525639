.recomended--img{
	img{
		border: 2px solid $brand-primary;
	}

	&:hover{
		.resizer{
			display: block;
		}
	}
}
