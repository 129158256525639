/**
 * COLORS
 */

$black-c-1: #171a1d;
$black-c-2: #212121;
$black-c-3: #3A3A3A;

$grey-c-1:  #575a5e;
$grey-c-2:  #a1a2a2;
$grey-c-3:  #d8d8d8;
$grey-c-4:  #979797;
$grey-c-5:	#e6efe9;

$grey-100: #F5F5F5;
$grey-300: #E0E0E0;
$grey-500: #9E9E9E;
$grey-600: #BDBDBD;

$white:     #ffffff;

$red: red;

$facebook-c-1: #3b5998;
$facebook-c-2: #19579D;

$green-c-1: #4CAF50;

$pink-c-1: #EC407A;

/**
 * border-color
 */
$border-grey:  #e6efe9;

$red-c-1: #E57373;

$body-bg: #F4F5F5;