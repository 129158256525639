.home-slider-container{
	padding: 45px 45px;
	padding-right: 44px;
	// background-color: #000;

	.selected { 
		img{
			border: 2px solid $brand-primary;
		}
	}
}

@media (max-width: 1680px){
   .home-slider-container{
       .selected { 
           img{
                border: 3px solid $brand-primary;
                padding-right: 1px;
           }
       }
   }
}

