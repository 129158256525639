.text-main{
	padding-top: 50px;

	// *:not(i){
	// 	font-family: gautami;
	// }

	@at-root #{&}__container{
		text-align: center;
	}

	@at-root #{&}__title{
		font-size: 45px;
		line-height: 45px;
		text-align: center;
		// color: $brand-primary;
		// padding-bottom: 20px;
	}
}

.text-main--break{
	border-top: 2px solid $brand-primary;
	width: 50%;
	content: "";
	margin-bottom: 30px;
}

.text-main--btn{
	cursor: pointer;
	color: #fff;
	padding: 8px;
	padding-bottom: 0;
	font-size:16px;
	border-radius: 4px;
	letter-spacing: 1px;
}