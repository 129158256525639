.inner-dropdown-override{
	padding: 10px;
	right: 16px;
	
	li{
		margin-right: 0 !important;
		a{
			line-height: 1px;
			padding: 10px 15px;
			padding-top: 20px;
			color: #333 !important;
		}
	}
}