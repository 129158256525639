/**
 * HELPERS
 */

// hidden
.hidden {
	display: none;
}

.hidden-o{
    opacity: 0;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-title{
    text-transform: capitalize;
}

.text-bold {
    font-weight: 700;
}

.fill-primary {
    fill: $brand-primary;
}

.fill-white {
    fill: $white;
}

/**
 * float
 */
.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

/**
 * color
 */
.text-black {
    color: $black-c-1;
}

.text-primary{
    color: $brand-primary;
}

a.text-primary:hover{
    color: $brand-primary;
}

.text-grey {
    color: $grey-c-1;
}

.text-grey-2 {
    color: $grey-c-2;
}

.text-white {
    color: $white;
}

.text-shadow{
    text-shadow: 1px 1px #000;
}

/**
 * font-size
 */
.text-small {
    font-size: $font-size-small;
}

.text-small-m {
    font-size: 16px;
}


.text-med {
    font-size: 20px;
}

.large-font{
    font-size: 36px;
}

/**
 * text-align
 */
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

/**
 * max-width unset
 */
.max-width-none {
    max-width:  none;
}


.max-height{
    height: 100%;
}


// SECTION SPACE
.section-space-s{
    padding-top: 20px !important;
}

.section-space-m{
    padding-top: 40px !important;
}

.section-space-l{
    padding-top: 60px !important;
}

.section-v-space-s{
    padding: 20px 0 !important;
}

.section-v-space-m{
    padding: 30px 0 !important;
} 

.section-v-space-l{
    padding: 60px 0 !important;
}

.section-h-space-s{
    padding: 0 20px !important;
}

.section-h-space-m{
    padding: 0 30px !important;
} 

.section-h-space-l{
    padding: 0 60px !important;
}

.section-l-space-s{
    padding-left: 20px;
}

.section-l-space-m{
    padding-left: 40px;
}

.section-l-space-l{
    padding-left: 60px;
}

.section-r-space-s{
    padding-left: 20px;
}

.section-r-space-m{
    padding-left: 40px;
}

.section-r-space-l{
    padding-left: 60px;
}

.letter-space{
    position: relative;
    left: -5px;
}


/**
 * background
 */
.bg-primary {
    background: $brand-primary;
}

.bg-black{
    background-color: #000;
}

/**
 * border
 */
.border-top {
    border-top: solid 0.5px $border-grey;
}

.border-bottom {
    border-bottom: solid 0.5px $border-grey;
}

.border-left {
    border-left: solid 0.5px $border-grey;
}

.border-right {
    border-right: solid 0.5px $border-grey;
}

.border {
    border: solid 0.5px $border-grey;
}

.border-shadow {
    box-shadow: 0 0 4px 0 rgba(20,20,21,0.1);
}

/**
 * padding
 */
.remove-padding {
    padding: 0px;
}

.remove-padding-left {
    padding-left: 0px;
}

.remove-padding-right {
    padding-right: 0px;
}

.remove-padding-top {
    padding-top: 0px;
}

.remove-padding-bottom {
    padding-bottom: 0px;
}

.remove-padding-left-right {
    padding-left: 0px;
    padding-right: 0px;
}

.add-padding {
    padding: 20px;
}

/**
 * Margin
 */
.center {
    margin: 0 auto;
}

.zero-margin {
    margin: 0;
}

/**
 * background
 */
.bg-white {
    background: $white;
}

/**
 * Error & Help
 */
.help-block {
    color: $red-c-1;
    font-size: $font-size-small;
}

.has-error {
    border: 0.5px solid $red-c-1 !important;
}

.gautami-text{
    font-family: gautami;
    padding-top: 10px;
}

.btn-hover:hover{
    color: #000 !important;
}

.btn-style{
    // d82731
    border:1px solid darken(#d82731,10%); 
    -webkit-border-radius: 3px; 
    -moz-border-radius: 3px;
    background-color: #d82731; 
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d82731), to(lighten(#d82731,3%)));
    background-image: -webkit-linear-gradient(top, #d82731, lighten(#d82731,3%));
    background-image: -moz-linear-gradient(top, #d82731, lighten(#d82731,3%));
    background-image: -ms-linear-gradient(top, #d82731, lighten(#d82731,3%));
    background-image: -o-linear-gradient(top, #d82731, lighten(#d82731,3%));
    background-image: linear-gradient(to bottom, #d82731, lighten(#d82731,3%));
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#d82731, endColorstr=lighten(#d82731,3%));
    box-shadow: 0 3px 0 0 darken(#d82731,10%);
}


.btn-style-black{
    // d82731
    border:1px solid #000; 
    -webkit-border-radius: 3px; 
    -moz-border-radius: 3px;
    background-color: lighten(#000000,1%) !important;
    box-shadow: inset 0 0 5px 2px #000;
    // background-image: -webkit-gradient(linear, left top, left bottom, from(lighten(#000,5%)), to(#000000));
    // background-image: -webkit-linear-gradient(top, lighten(#000,5%) ,#000000);
    // background-image: -moz-linear-gradient(top, lighten(#000,5%) ,#000000);
    // background-image: -ms-linear-gradient(top, lighten(#000,5%) ,#000000);
    // background-image: -o-linear-gradient(top, lighten(#000,5%) ,#000000);
    // background-image: linear-gradient(to bottom, lighten(#000,5%) ,#000000);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=lighten(#000,5%), endColorstr=#000);
    // box-shadow: 0 3px 0 0 #000;
}

.btn-style:hover{
    a{
        color: #000 !important;
    }
    border:1px solid #b30808;
    background-color: #e40a0a; 
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e40a0a), to(#9f0202));
    background-image: -webkit-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -moz-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -ms-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -o-linear-gradient(top, #e40a0a, #9f0202);
    background-image: linear-gradient(to bottom, #e40a0a, #9f0202);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e40a0a, endColorstr=#9f0202);
}

.btn-style-dark{
    a{
        // color: #000 !important;
    }
    border:1px solid #b30808;
    background-color: #e40a0a; 
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e40a0a), to(#9f0202));
    background-image: -webkit-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -moz-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -ms-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -o-linear-gradient(top, #e40a0a, #9f0202);
    background-image: linear-gradient(to bottom, #e40a0a, #9f0202);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e40a0a, endColorstr=#9f0202);
    box-shadow: inset 0 0 5px 2px darken($brand-primary,10%);
    font-size: 16px !important;
    min-width: 140px !important;
    min-height: 42px;
    cursor: pointer;
}

.btn-style-dark:hover{
    color: #000 !important;
    a,span{
        color: #000 !important;
    }

    .premiere-title--svg{
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEzNCAxNDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEzNCAxNDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHJva2U9IiMwMDAiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiMwMDA7fTwvc3R5bGU+PGcgaWQ9IlhNTElEXzJfIj48ZyBpZD0iWE1MSURfMV8iPjxwYXRoIGlkPSJYTUxJRF84N18iIGNsYXNzPSJzdDAiIGQ9Ik0zMC43LDEzNi43YzAsMi44LTIuMyw1LTUsNWgtMTFjLTIuOCwwLTUtMi4zLTUtNVYxM2MwLTIuOCwyLjMtNSw1LTVoMTFjMi44LDAsNSwyLjMsNSw1VjEzNi43eiIvPjwvZz48ZyBpZD0iWE1MSURfM18iPjxwYXRoIGlkPSJYTUxJRF84NV8iIGNsYXNzPSJzdDAiIGQ9Ik04Mi4zLDEzNi43YzAsMi44LTIuMyw1LTUsNWgtMTFjLTIuNywwLTUtMi4zLTUtNVYxM2MwLTIuOCwyLjMtNSw1LTVoMTFjMi44LDAsNSwyLjMsNSw1VjEzNi43eiIvPjwvZz48ZyBpZD0iWE1MSURfNF8iPjxwYXRoIGlkPSJYTUxJRF84M18iIGNsYXNzPSJzdDAiIGQ9Ik01Ni4zLDEzNi43YzAsMi44LTIuMyw1LTUsNWgtMTFjLTIuOCwwLTUtMi4zLTUtNXYtMTA2YzAtMi44LDIuMy01LDUtNWgxMWMyLjgsMCw1LDIuMyw1LDVWMTM2Ljd6Ii8+PC9nPjxnIGlkPSJYTUxJRF81XyI+PHBhdGggaWQ9IlhNTElEXzgxXyIgY2xhc3M9InN0MCIgZD0iTTEyOS45LDEzMi4xYzAuNywyLjctMSw1LjQtMy43LDYuMWwtMTAuNywyLjZjLTIuNywwLjctNS40LTEtNi4xLTMuN0w4Ni40LDQzLjJjLTAuNy0yLjcsMS01LjQsMy43LTYuMWwxMC43LTIuNmMyLjctMC43LDUuNCwxLDYuMSwzLjdMMTI5LjksMTMyLjF6Ii8+PC9nPjwvZz48L3N2Zz4=) !important;
    }
}

.btn-style-black:hover{
    // color: #9f0202 !important;
    // a{
        color: #e40a0a !important;
    // }
    // border:1px solid #000;
    // background-color: lighten(#000,5%); 
    // background-image: -webkit-gradient(linear, left top, left bottom, from(lighten(#000,5%)), to(#000000));
    // background-image: -webkit-linear-gradient(top, lighten(#000,5%), #000000);
    // background-image: -moz-linear-gradient(top, lighten(#000,5%), #000000);
    // background-image: -ms-linear-gradient(top, lighten(#000,5%), #000000);
    // background-image: -o-linear-gradient(top, lighten(#000,5%), #000000);
    // background-image: linear-gradient(to bottom, lighten(#000,5%), #000000);
    // filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=lighten(#000,5%), endColorstr=#000000);
}

.btn-style-custom{
    // d82731
    border:1px solid #b30808;
    background-color: #e40a0a; 
    background-image: -webkit-gradient(linear, left top, left bottom, from(lighten(#e40a0a,15%)), to(#9f0202));
    background-image: -webkit-linear-gradient(top, lighten(#e40a0a,15%), #9f0202);
    background-image: -moz-linear-gradient(top, lighten(#e40a0a,15%), #9f0202);
    background-image: -ms-linear-gradient(top, lighten(#e40a0a,15%), #9f0202);
    background-image: -o-linear-gradient(top, lighten(#e40a0a,15%), #9f0202);
    background-image: linear-gradient(to bottom, lighten(#e40a0a,15%), #9f0202);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=lighten(#e40a0a,15%), endColorstr=#9f0202);
}


.btn-style-custom:hover{
    // d82731
    border:1px solid #b30808;
    background-color: #e40a0a; 
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9f0202), to(lighten(#e40a0a,15%)));
    background-image: -webkit-linear-gradient(top, #9f0202, lighten(#e40a0a,15%));
    background-image: -moz-linear-gradient(top, #9f0202, lighten(#e40a0a,15%));
    background-image: -ms-linear-gradient(top, #9f0202, lighten(#e40a0a,15%));
    background-image: -o-linear-gradient(top, #9f0202, lighten(#e40a0a,15%));
    background-image: linear-gradient(to bottom, #9f0202, lighten(#e40a0a,15%));
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#9f0202, endColorstr=lighten(#e40a0a,15%));
}

// .tooltip-description[data-tooltip]:hover:after, .tooltip-description[data-tooltip]:hover:before {
//     display: block;
// }

/**
 * Position
 */
.relative {
    position: relative;
}

.absolute{
    position: absolute !important;
}

.padding-tb-10{
    padding: 10px 0;
}

.left-position{
    left: 20%;
    top: 6px;
}

/**
 * Wordwrap
 */
.break-word {
    word-wrap: break-word;
}

/**
 * Click spam Stopper
 */
.stopper
{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    left: 0;
    outline: 0;
    display: none;
}

.btn-subs{
    border-radius: 3px;
    width: 140px;
    padding-top: 14px;
    font-family: gautami;
}

.hidden-big{
    display: none;
}

.mobileMenuToggle{
    cursor: pointer;
}

.middle-spacing + .middle-spacing{
    padding-left: 10px;
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
    .max-width-xs{
        width: 100% !important;
    }

    .mobile-search{
        width: 50%
    }

    .hidden-xs {
        display: none !important;
    }

    .hidden-big{
        display: block !important;
    }

    .visible-xs {
        display: block !important;
    }

    .visible-xs-headlines {
        display: block !important;
    }

    .padding-content-xs{
        * + *{
            padding-top: 20px;
        }
    }

    .btn-subs{
        padding-left: 30px;
        width: 130px;
    }

    .middle-spacing + .middle-spacing{
        padding-left: 0;
        padding-top: 20px;
    }

    .section-h-space-s{
        padding: 0 !important;
    }

    .section-h-space-m{
        padding: 0 !important;
    } 

    .section-h-space-l{
        padding: 0 !important;
    }
}

@media (max-width: $screen-sm - 1){
    .hidden-xs-phone {
        display: none !important;
    }   

    .section-l-space-s{
        padding-top: 20px;
        padding-left: 0;
    }

    .section-l-space-m{
        padding-top: 40px;
        padding-left: 0;
    }

    .section-l-space-l{
        padding-top: 60px;
        padding-left: 0;
    }

    .section-r-space-s{
        padding-top: 20px;
        padding-left: 0;
    }

    .section-r-space-m{
        padding-top: 40px;
        padding-left: 0;
    }

    .section-r-space-l{
        padding-top: 60px;
        padding-left: 0;
    }

    .letter-space{
        left: 0;
    }
}

@media (max-width: 1024px) and (orientation: portrait){
    .hidden-ipad {
        display: none !important;
    }
}