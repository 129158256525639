.banner-title{
	height: 100%;
	padding: 0 110px;

	z-index: 10;

	padding-bottom: 8px;

	.grow-3{
		padding-left: 25px;
	}
}