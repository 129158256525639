.premiere-slides{
	width: 100%;
	height: 100%;

 	// SLICK OVERRIDES
	.slick-slide {
    	outline: none !important;
	}

	.slick-dots{
		display: none !important;
	}

	.slick-list{
		height: 100%;
	}
	// END SLICK

	//premiere-slides
	.summary-arrows{
		bottom: 0;
		width: 100%;

		.arrow-prev-premiere,
		.arrow-next-premiere{
			position: absolute;
			bottom: 0;
		}

		.arrow-prev-premiere{
			left: -30px;
		}

		.arrow-next-premiere{
			right: -30px;
		}
	}

	.premiere-logo-text{
		cursor: pointer;
	}

	@at-root #{&}__item{
		width: 100%;
	}

	@at-root #{&}__classic{
		position: absolute;
		top: 2px;
		right: 20px;
		z-index: 10;
		// font-weight: 600;
		font-family: gautami;
		padding-top: 30px;
		font-size: 40px;
		// color: #e40a0a;
		color: #f50000;
		-webkit-text-stroke-width: 1px;
   		-webkit-text-stroke-color: black;
		// text-shadow: 2px 2px rgba(#000,.6);
	}

	@at-root #{&}__title{

		position: relative;
		top: -10px;

		*{
			font-size: 41px;
		}

		h1{
			color: #fff;
			margin: 0;
			font-size: 47px//36px;
		}

		.glyphicon-star{
			color: $brand-primary;
			position: relative;
			top: -1px;
		}

		.rate{
			position: relative;
			top: -5px;
		}

	}

	@at-root #{&}__arrows{
		position: absolute;
	    color: #d82731;
	    font-size: 42px;

	    transition: all 1500ms ease-in-out;

	    .arrow-prev-premiere,
	    .arrow-next-premiere{
	    	border: 1px solid #d82731;
	    	border-radius: 3px;
    	    width: 50px;
    	    height: 50px;
    	    cursor: pointer;
	    }

	    .grow-1 + .grow-1{
	    	margin-top: 10px;
	    }
	}

	@at-root #{&}__content{
		padding: 0 110px;
	}

	@at-root #{&}__content-title{
		display: none;
		padding-bottom: 10px;
	}

	@at-root #{&}__container{
		// top: -90px;

		.grow-1{
			padding-right: 25px;

			img{
				max-width: 100%;
				// margin-top: -85px;
				margin-top: -95px;
			}
		}
	}

	@at-root #{&}__details{
		p{
			color: #fff;
		}

		text-align: justify;

		.btn-actions{
			margin: 20px 0;

			button:hover{
				color: #000;
			}

		}

		.synopsis{
			padding-right: 40px;
		}
	}

	@at-root #{&}__logo{
		position: absolute;
		max-width: 100px;
		top: -10px;
		right: -82px;
		cursor: pointer;

		h1{
			color: $brand-primary;
			font-size: 16px;
			font-family: "Gautami";
		}
		
		text-align: center;

	}

	@at-root #{&}__space{
		padding-bottom: 20px;
	}

	@at-root #{&}__background-container{
		// max-height: 46vh;
	    width: 100%;
	    overflow: hidden;
	}

	@at-root #{&}__background{
		margin: 0 auto;
		height: 0;
		position: relative;

		z-index: 0;

		@include aspect-ratio(1280px, 320px);
		width: 100%;
		height: 100%;

		.main-img{
			margin: 0 auto;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: top left;
		}

		// 

		&:after{
			position: absolute;
		    content: '';
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background-color: rgba(0, 0, 0, 0.5);
		    z-index: 1;
		}


	}
}

@media (min-width: 1366px) and (min-height: 900px){
	.premiere-slides{
		@at-root #{&}__container{
			// top: 45px;
			.grow-1{
				img{
					margin-top: 0;
				}
			}
		}

		@at-root #{&}__content{
			padding: 40px 110px;
		}

		@at-root #{&}__content-title{
			display: block;
		}

		@at-root #{&}__details{
			font-size: 22px;
			line-height: 40px;
		}
	}

	.banner-title{
		display: none !important;
	}
}

@media (max-width: 1366px) and (max-height: 900px){
	.premiere-slides{
		@at-root #{&}__details{
			font-size: 16px;
			line-height: 25px;
		}
	}
}


// @media (min-width: 1920px) and (max-height: 1080px) and (min-height: 900px){
// 	.premiere-slides{
// 		@at-root #{&}__details{
// 			font-size: 22px;
// 			line-height: 40px;
// 		}
// 	}
// }

@media (max-width: 1366px) and (max-height: 700px){
	.premiere-slides{
		@at-root #{&}__container{
			// top: -70px;

			.rate{
				line-height: 10px;
			}

			.grow-1{
				img{
					max-width: 85%;
				}
			}
		}

		// @at-root #{&}__details{
		// 	.btn-actions{
		// 		margin: 0 0;
		// 		margin-bottom: 10px;
		// 	}
		// }
	}
}

@media (max-width: 1280px){
	.premiere-slides{
		@at-root #{&}__container{
			.grow-1{
				img{
					max-width: 90%;
					margin-top: -80px;
				}
			}
		}

		@at-root #{&}__title{
			*{
				font-size: 30px;
			}

			h1{
				font-size: 32px;
			}	
		}

		.summary-arrows{
			.arrow-prev-premiere{
				left: -45px;
			}

			.arrow-next-premiere{
				right: -45px;
			}
		}
	}
}


@media (min-width: 1280px) and (min-height: 900px){
	.premiere-slides{
		@at-root #{&}__container{
			// top: 45px;
			.grow-1{
				img{
					max-width: 100%;
					margin-top: 0;
				}
			}
		}

		@at-root #{&}__content{
			padding: 40px 110px;
		}

		@at-root #{&}__content-title{
			display: block;
		}

	}

	.banner-title{
		display: none !important;
	}
}

// IPAD
@media (max-width: 1024px){
	.premiere-slides{
		@at-root #{&}__container{
			// top: 45px;
			.grow-1{
				img{
					margin-top: 0;
					max-width: 100%;
				}
			}
		}

		@at-root #{&}__content{
			padding: 40px 50px;
		}

		@at-root #{&}__content-title{
			display: block;
			font-size: 16px;
		}

		@at-root #{&}__logo{
			right: -20px;
		}

		@at-root #{&}__title{
			h1{
				font-size: 30px;
			}
		}

		@at-root #{&}__details{
			font-size: 16px;
			line-height: 22px;

			.synopsis{
				padding-right: 90px;
			}
		} 
	}

	.banner-title{
		display: none !important;
	}
}

@media (max-width: 1024px) and (orientation: portrait){
	.premiere-slides{
		.arrow-position{
			display: none;
		}

		@at-root #{&}__container{
			.grow-1{
				img{
					max-width: 80%;
					margin-bottom: 30px;
				}
			}
		}
	}
}

@media (max-width: $screen-sm - 1),(max-width: 812px){
	.premiere-slides{
		@at-root #{&}__container{
			margin-top: 20px !important;

			.rate{
				line-height: normal;
			}

			.grow-1{
				img{
					max-width: 100%;
					margin-top: 0;
				}
			}
		}

		@at-root #{&}__details{
			.btn-actions{
				margin-bottom: 20px;
			}
		}

		@at-root #{&}__logo{
			position: relative;
			margin: 0 auto;
			top: 0 !important;
			right: auto;
		}

		.arrow-position{
			right: -94px !important;
			left: auto !important;
			top: 15px !important;
		}

		@at-root #{&}__title{
			*{
				font-size: 24px;
			}

			h1{
				font-size: 24px;
			}
		}

		@at-root #{&}__item{
			* .grow-1{
				padding-right: 0;
			}
		}

		@at-root #{&}__details{
			.synopsis{
				padding-right: 0;
			}
		}

		@at-root #{&}__space{
			padding: 20px 0;
			font-size: 15px;
		}

		@at-root #{&}__content-title{
			display: block;
			padding-top: 20px;
			padding-bottom: 10px;
		}

		//premiere-slides
		.summary-arrows{
			bottom: 50%;

			.arrow-prev-premiere{
				left: -45px;
			}

			.arrow-next-premiere{
				right: -45px;
			}

			.flex-container{
				top: -15px;
			}
		}
	}

	.banner-title{
		display: none !important;
	}
}