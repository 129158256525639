.subscribe-section{
	position: fixed;
	bottom: 0;
	// margin: 0 16.3vw;
	z-index: 10;
	width: 100%;
	padding: 0 16vw;

	@at-root #{&}__container{
		transition: all 0.25s ease-in-out;
		background-color: #000;
		// padding: 0 12vw;
		margin-bottom: -100vh;
		border: 1px solid lighten($brand-primary,5%);
		z-index: 100;

		img{
			max-width: 6vw;
			padding-top: 10px;
			margin-left: 10px;
		}
	}

	@at-root #{&}__title{
		color: $brand-primary;
		font-weight: 900;
		font-size: 40px;
		padding-bottom: 30px;
		font-style: italic;
	}

	@at-root #{&}__text{
		span{
		    font-size: 14px;
		}

		p{
		    margin: 0;
		    color: #fff;
		    font-size: 18px;
		    text-align: center;
		    strong{
		        color: $brand-primary;
		    }
		}
	}


	@at-root #{&}__text--later{
	    text-decoration: underline;
	    color: $brand-primary;
	    cursor: pointer;
	}

	@at-root #{&}__text--error{
	    display: none;
	    position: absolute;
	    font-size: 20px !important;
	}

	@at-root #{&}__text--input{
	    position: relative;
	    i{
	        position: absolute;
	        right: 13px;
	        font-size: 25px;
	        cursor: pointer;
	        color: $brand-primary;
	    }

	      input[type=email]{
	        padding: 5px 0;
	        padding-left: 20px;
	        padding-right: 45px;
	        font-size: 25px;
	        border: 1px solid #d82731;
	        height: 41px;
	        width: 350px;
	        background-color: #000;
	        color: #fff;
	        outline: none;
	    }

	    input[type=email]::placeholder { 
	        color: #d82731;
	        opacity: 1;
	    }
	}

	@at-root #{&}__space{
		padding-top: 40px;
	}

	@at-root #{&}__opener{
		position: absolute;
		display: none;
		top: -43px;
		// right: -1px;
		padding: 10px;
		padding-bottom: 3px;
		border-radius: 4px 4px 0 0;
		cursor: pointer;

		font-size: 25px;
		// font-weight: bold;
		color: #fff;
		background-color: $brand-primary;
	}

	/* The Close Button */
	.close {
	    position: absolute;
	    top: 5px;
	    right: 12px;
	    color: lighten($brand-primary,10%);
	    font-size: 40px;
	    font-weight: bold;
	    opacity: 5;
	    text-shadow: none;
	    z-index: 20;
	}

	.close:hover,
	.close:focus {
	    color: $brand-primary;
	    text-decoration: none;
	    cursor: pointer;
	}
}

.subscribe-section--open{
	margin-bottom: -1px !important;
}


@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
	.subscribe-section{
		padding: 0 20px;

		@at-root #{&}__container{
			padding: 0 10px;

			img{
				max-width: 15vw;
			}
		}

		@at-root #{&}__space{
			padding-top: 90px;
		}

		@at-root #{&}__text{
			width: 100%;
		}

		@at-root #{&}__text--input{
		    input[type=email]{
		        width: 100%;
		    }
		}
	}
}