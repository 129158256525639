.history-img{
	padding-right: 10px;

	img{
		width: 80%;
	}
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
	.history-img{
		padding-right: 0;

		img{
			width: 100%;
			padding-bottom: 30px;
		}
	}
}