/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2000; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    overflow: auto; /* Enable scroll if needed */
    -webkit-overflow-scrolling: touch; /* momentum scrolling */
   
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
        // z-index: 10;
    }
    
    .modal__content{
        margin: auto;
        display: block;
        text-align: center;
        max-width: 800px;
        margin-top: 10vh;
    }

    /* The Close Button */
    .close {
        position: absolute;
        top: 15px;
        right: 35px;
        color: #f1f1f1;
        font-size: 40px;
        font-weight: bold;
        transition: 0.3s;
        opacity: 1;
        z-index: 20;
    }

    .close:hover,
    .close:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

}


@media (max-width: $screen-sm - 1){
    .modal{
        .modal__content {
            width: 100%;
            margin-top: 5vh;
        }
    }
}