.mobile-player{
	padding-bottom: 20px;

	@at-root #{&}__btn{
		min-height: 42px;

		.grow-1 + .grow-1{
			margin-left: 10px;
		}
	}

	.mobile-player__btn + .mobile-player__btn{
		margin-top: 10px;
	}
}