.custom-src{
    color: #fff;
    position: relative;
    display: inline-block;

    input{
		// background-color: rgba($brand-primary,0.6);
	    border: 0;
	    background-color: #000;
	    height: 32px;
	    color: inherit;
	    outline: none;
	    width: 0;

	    &::placeholder{
	    	color: $brand-primary;
	    }
    }

    span{
    	position: absolute;
	    top: 8px;
	    right: 5px;
	    z-index: 2;
	    display: block;
	    cursor: pointer;
	    color: $brand-primary;
    }
    // width: 30px;
}

.custom-src--mobile{
	input{
		border-radius: 50px;
		width: 100% !important;

		padding: 0px 25px 0px 10px;
	    border: 1px solid $brand-primary;
	}
	
	.glyphicon-search{
		right: 10px;
	}
}