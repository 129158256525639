.premieretitle{
	background-color: #000;
	@at-root #{&}__container{
		height: 100%;
	}
}


@media (min-width: 1200px) and (max-height: 600px){
	.premieretitle{
		height: auto;
	}
}

@media (max-width: $screen-sm - 1), (max-width: 991px){
	.premieretitle{
		height: auto;
	}
}