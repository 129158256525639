.main-menu-overrides{
	list-style: none;
	margin: 0;
	padding: 0;
	// padding-top: 5px;

	li:first-child{
		margin-right: 10px;
	}

	li{
		min-width: 70px;
		text-align: center;

		a{
			color: $brand-primary;
			font-family: gautami;
			font-size: 12px;
			line-height: 0;
		}	
	}
}