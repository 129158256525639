.post-contents{
	img{
		width: 116px;
		margin-right: 20px;
	}

	@at-root #{&}__container{
		width: 100%;
		min-height: 71px;
		h4{
			margin: 0;
			// font-size: 16px;
		}

		span{
			font-size: 13px;
			font-weight: bold;
		}
	}
}

.post-contents + .post-contents{
	margin-top: 20px;
}

@media (max-width: $screen-sm - 1), (max-width: $screen-md - 1){
    .post-contents{
    	img{
    		width: 100%;
    	}

    	@at-root #{&}__container{
    		text-align: center;
    		h4{
    			margin-top: 10px;
    		}
    	}
    }
}