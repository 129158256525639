.apix-divider{
	background-color: $brand-primary;
	padding: 10px 0;
	scroll-snap-align: start;

	img{
		width: 70px;
	}

	@at-root #{&}__title{
		padding-left:30px;
		padding-top: 20px;
		margin-right: -15px;
		letter-spacing: 10px;
		line-height: 40px;

		font-family: Gautami;
		font-weight: bold;
		font-size: 35px;

		color: #000;
	}
}

@media (max-width: $screen-sm - 1) and (orientation: portrait){
	.apix-divider{

		@at-root #{&}__title{
			padding-left: 20px;
			font-size: 14px;//25px;
		}

		img{
			width: 60px;
		}
	}
}